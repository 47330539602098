<!--
 * @Description: 房态
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:41:13
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-10 17:30:39
-->
<template>
  <div class="bar">
    <el-radio-group v-model="calendarRadio">
      <el-radio-button label="日历" />
      <el-radio-button label="单日" />
    </el-radio-group>
    <div style="flex: 1;padding-left: 20px;">
      <!-- <el-select v-model="shop_id" placeholder="选择门店" filterable @change="shopChange">
        <el-option v-for="item in shop_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select> -->
    </div>
    <div class="flex-row item-center">
      <key-search></key-search>
      <div style="margin-left: 20px">
        <batch-on-off :huxing_stores="group_stores" :enums="enums" @success="onOffSuccess"></batch-on-off>
      </div>
      <el-button style="margin-left: 20px" plain @click="onNavToPrice" v-power="'seller/v1/housePrice/prices'">房价管理</el-button>
    </div>
  </div>
  <calendar ref="calendar" :shop_id="shop_id"></calendar>
</template>

<script>
import common_api from "@/http/common_api";
import BatchOnOff from "./batch_on_off.vue";
import KeySearch from "./key_search.vue";
import Calendar from "./calendar.vue";
import shop_api from "@/http/shop_api";

export default {
  components: {
    BatchOnOff,
    KeySearch,
    Calendar,
  },
  data() {
    return {
      calendarRadio: "日历",
      loading: false,
      //保存关房
      // saving: false,
      // visibleCloseRoomDialog: false,
      isOpenBook: false,
      // visiblePopover: false,

      enums: {},
      //表格日期头列表
      // calendar_list: [],
      //日历参数
      query: {
        q: "",
        days: 30,
        date: "",
      },
      //房源列表
      // data_list: [],
      // shortcuts: [
      //   {
      //     text: "今天",
      //     value: new Date(),
      //   },
      //   {
      //     text: "Yesterday",
      //     value: () => {
      //       const date = new Date();
      //       date.setTime(date.getTime() - 3600 * 1000 * 24);
      //       return date;
      //     },
      //   },
      //   {
      //     text: "一个星期",
      //     value: () => {
      //       const date = new Date();
      //       date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
      //       return date;
      //     },
      //   },
      // ],
      //显示dialog
      //dialogVisible: false,
      //订单id
      // current_order_id: null,
      order: null,
      // closeForm: {},
      // closeRules: {
      //   price: [
      //     {
      //       required: true,
      //       message: "必填价格",
      //       trigger: "blur",
      //     },
      //   ],
      // },   
      //分组的房源
      group_stores: [],  
      //选择的门店
      shop_id: "",
      shop_list: [],
    };
  },
  created() {
    
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    shop_api.get_my_shops().then((res) => {
      if (res.code == 0) {
        this.shop_list = res.data;
        if (this.shop_list.length > 0) {
          this.shop_id = this.shop_list[0].id;
        }
      }
    });
  },
  methods: {
    /**
     * 跳转房价管理
     */
    onNavToPrice() {
      window.location.href = "/house/price";
    },
    /**
     * 加载数据
     */
    // loadData() {
    //   this.loading = true;
    //   this.$http
    //     .post("seller/v1/roomState/stores", this.query)
    //     .then((res) => {
    //       if (res.code == 0) {
    //         var list = [];
    //         this.group_stores = res.data;
    //         res.data.forEach((item) => {
    //           var i = 0;
    //           if (item.store_list && item.store_list.length > 0) {
    //             item.store_list.forEach((store) => {
    //               list.push({
    //                 name: item.name,
    //                 child_name: "",
    //                 column_first: i === 0 ? item.store_count : 0,
    //                 column_second: 1,
    //                 store_id: store.id,
    //                 house_no: store.house_no,
    //                 clean_status: store.clean_status,
    //               });
    //               i++;
    //             });
    //           }
    //         });
    //         this.data_list = list;
    //         this.loadOrder();
    //       }
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },

    /**
     * 净房、藏房转换
     */
    onCleanToggle(item) {
      this.$confirm(item.clean_status == 0 ? "转为脏房" : "转为净房", "提示")
        .then(() => {
          this.$http
            .post("seller/v1/roomState/setRoomCleanStatus", {
              id: item.store_id,
              clean_status: item.clean_status == 0 ? 1 : 0,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                item.clean_status = item.clean_status == 0 ? 1 : 0;
              }
            });
        })
        .catch(() => {});
    },
    
    /**
     * 批量开关房回调
     */
    onOffSuccess() {
      this.$refs.calendar.loadSolar();
    },
  },
};
</script> 

<style scoped>
.bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<style >
.room_state .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.room_state .header .week,
.room_state .header .holiday {
  font-weight: normal;
}

.room_state .header.light .week,
.room_state .header.light .date {
  color: var(--el-color-danger);
}

.room_state .header .holiday.green {
  color: var(--el-color-success);
}

/* 表格 */
.room_state .room {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
}

.room_state .room.dirty {
  background: #d4d7de;
}

.room_state .room:hover {
  border-color: #409eff;
}

.room_state .room .house_no {
  display: block;
}

.room_state .room .house_no_hint {
  display: none;
}

.room_state .room:hover .house_no {
  display: none;
}

.room_state .room:hover .house_no_hint {
  display: block;
}

.room_state .room.dirty .el-icon {
  position: absolute;
  right: 0px;
  bottom: 2px;
  transform: rotate(-145deg);
  color: #a8abb2;
}

.room_state .el-table .cell {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.room_state .el-table .price {
  color: #a8abb2;
}

.room_state .el-table .store {
  font-size: 12px;
  color: #c0c4cc;
}

.room_state .cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.room_state .cover.item .checked {
  position: absolute;
  background: #c6e2ff;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.room_state .cover.item .checked .el-icon {
  color: #409eff;
}

/* 关房 */
.room_state .close {
  color: #909399;
  background: #d4d7de;
  z-index: 10;
}

.room_state .cover.close.checked {
  border: 1px solid #409eff;
}

.room_state .cover.close .checked-icon {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  background: #409eff;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
}

.room_state .cover.close.checked .checked-icon {
  display: block;
}

.text_type {
  font-size: 12px;
}

.room_state .cover.order {
  z-index: 10;
  height: 100%;
  align-items: flex-start;
  padding-left: 10px;
  border-radius: 4px;
  color: #000;
  overflow: hidden;
  background-color: #6c928c;
}

/* 订单状态 */
.room_state .status {
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 20px;
  transform: rotate(-35deg);
  background-color: #eebe77;
  right: -10px;
  bottom: -8px;
}

.room_state .order .status.status_0 {
  background-color: #eebe77;
}

.room_state .order .status.status_10 {
  background-color: #79bbff;
}

.room_state .order .status.status_20 {
  background-color: #95d475;
}

.room_state .order .status.status_30 {
  background-color: #529b2e;
}

.room_state .order .status.status_40 {
  background-color: #008b8b;
}

.room_state .order .status.status_50 {
  background-color: #c8c9cc;
}

.room_state .order .status.status_60 {
  background-color: #c45656;
}

.room_state .owe {
  background: #f56c6c;
  color: white;
  font-size: 12px;
  width: 20px;
  position: absolute;
  z-index: 21;
  height: 20px;
  right: 2px;
  top: 2px;
  line-height: 20px;
  border-radius: 2px;
  text-align: center;
}

.tb_header_custom {
  /* background: var(--el-table-border-color) !important; */
  height: 100px;
}
</style>
